@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&display=swap");

/* ==================== Tooltip ==================== */
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
/* ================================================= */

/* ============ Remove Increase/Decrease Buttons on Inputs =============== */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove the number input's arrows in all browsers */
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

/* ======================================================================= */

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #000000;
  color: white;
  font-family: "Sofia Sans", sans-serif;
}

/* ========================= Custom Scrollbar ========================*/

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}

/* ===================================================== */

::-moz-selection {
  /* Code for Firefox */
  color: rgb(202, 36, 217);
  background: rgb(0, 0, 0);
}

::selection {
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
}
